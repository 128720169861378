import Vue from 'vue';
import { API_BASE_URL } from '@/config';
export default {
  save_offer (data) {
    let url = API_BASE_URL + 'customer/save_offer';
    return Vue.http.put(url, data);
  },
  get_user_wdmr_list (data) {
    let url = API_BASE_URL + 'customer/get_user_wdmr_list';
    return Vue.http.post(url, data);
  },
  get_wdmr_data (data) {
    let url = API_BASE_URL + 'customer/get_wdmr_data';
    return Vue.http.post(url, data);
  },
  calculate_product_price (data) {
    let url = API_BASE_URL + 'customer/calculate_product_price';
    return Vue.http.post(url, data);
  }
};
